/* Provide sufficient contrast against white background */
body {
  font-family: MetricHPE, "MS PGothic", Arial !important;
}

a {
  color: black;
  text-decoration: underline;
}

a:hover {
  color: #01a982;
}

.message-link {
  color: white !important;
}

.message-link:hover {
  color: #0d5265;
}

code {
  color: #e01a76;
}

.digital-badge {
  height: 240px;
  width: 240px;
}

.hpe_text {
  color: #01a982;
}

.breadcrumb {
  background-color: transparent;
  font-size: 1rem;
  padding: 0 0 0 0;
}

.fc-today-button {
  display: none;
}

.fc-button-primary,
.fc-prev-button,
.fc-next-button,
.btn-primary:focus {
  background-color: #01a982;
  border-color: #01a982;
}

.btn-primary {
  background-color: #01a982;
  border-color: #01a982;
}

.btn-primary:hover {
  background-color: #01a982;
  border-color: #01a982;
}

.hpe-button {
  border: 2px solid #01a982 !important;
  border-radius: 5px;
  background-color: #01a982 !important;
  color: white !important;
}

.hpe-button:hover {
  border: 2px solid #0d5265 !important;
}

.hpe-button-secondary {
  border: 2px solid #01a982 !important;
  border-radius: 5px;
}
.hpe-button-thick-border {
  border: 4px solid #01a982 !important;
  border-radius: 5px;
}

.hpe-button-box {
  border: 2px solid #01a982 !important;
  border-radius: 5px;
}

.page-link {
  color: #0d5265;
}

.page-item.active .page-link {
  background-color: #0D5265;
  border-color: #0D5265;
}

.striped .nested_table tbody tr:nth-child(even) {
  background-color: transparent !important;
}

.start-box {
  position: relative;
  top: -180px;
  left: 5px;
}

.bullet-list {
  list-style-type: square;
  padding-left: 20px;
}

#step2-anchor {
  cursor: pointer;
}

#step2-anchor:hover {
  cursor: pointer;
  color: #01A982;
}

.learner-link {
  text-decoration: underline;
  cursor: pointer;
}

.learner-link:hover {
  cursor: pointer;
  color: #01a982;
}

.active-menu {
  color: #01a982 !important;
}

.nav-btn {
  border-bottom: solid 3px #BBBBBB !important;
}

.nav-btn.active {
  border-bottom: solid 3px #666666 !important;
}

.lined:before,
.lined:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 100%;
}

.lined:before {
  right: 0.5em;
  margin-left: -100%;
}

.lined:after {
  left: 0.5em;
  margin-right: -100%;
}

.dot {
  height: 12.5px;
  width: 12.5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.dot.active {
  height: 12.5px;
  width: 12.5px;
  background-color: #01a982;
  border-radius: 50%;
  display: inline-block;
}

ul {
  padding-left: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border: 2px solid #01a982;
  background: none;
  text-align: center;
  border-radius: 100%;
  line-height: 16px;
  align-content: center;
}

.breadcrumb-item a:hover {
  cursor: pointer;
}

.breadcrumb a:hover {
  cursor: pointer;
}

.striped tbody tr:nth-child(even) {
  background-color: #efefef;
}

.videoContainer {
  position: relative;
}

.videoContainer::before {
  position: absolute;
  display: block;
  transform: translateX(-50%);
  left: 50%;
  width: 50%;
  height: 100%;
  content: '';
}

.image {
  object-fit: cover;
}

.whats-new a {
  color: #01a982;
}

.related-resources a {
  color: #01a982;
  font-weight: 700;
}
